<template>
    <ClientOnly>
        <Galleria
            v-if="hasItemsToDisplay"
            :value="portfolioItems"
            :active-index="activeIndex"
            :circular="true"
            :full-screen="isFullscreenModeActivated"
            :num-visible="maxVisibleThumbnails"
            :show-thumbnail-navigators="true"
            :thumbnails-position="isFullscreenModeActivated ? 'bottom' : 'right'"
            :visible="isFullscreenModeActivated"
            data-testid="portfolio-item-porfolio-content"
            vertical-thumbnail-view-port-height="280px"
            @update:active-index="updateActiveIndex"
            @update:visible="updateIsFullscreenModeActivated"
        >
            <template #item="slotProps: {item: PortfolioItem}">
                <template v-if="isFullscreenModeActivated">
                    <template v-if="slotProps.item.isVideo">
                        <iframe
                            v-if="isFullscreenModeActivated"
                            class="portfolio__item video-player"
                            :src="slotProps.item.url"
                            :alt="slotProps.item.title"
                            :title="slotProps.item.title"
                            :data-testid="`portfolio-item-video-iframe-${slotProps.item.index}`"
                        ></iframe>
                    </template>
                    <template v-else>
                        <MaltImg
                            :src="slotProps.item.portfolioImageMaxImageUrl"
                            :alt="slotProps.item.title"
                            :title="slotProps.item.title"
                            :data-testid="`portfolio-item-photo-${slotProps.item.index}`"
                            class="portfolio__item image"
                            width="1920"
                            height="1920"
                            fit="inside"
                        />
                    </template>
                    <div :data-testid="`portfolio-item-title-${slotProps.item.index}`">{{ slotProps.item.title }}</div>
                </template>
                <template v-else>
                    <MaltImg
                        :src="slotProps.item.portfolioImageMaxImageUrl"
                        :data-testid="`portfolio-item-embeded-thumbnail-${slotProps.item.index}`"
                        :alt="slotProps.item.title"
                        :title="slotProps.item.title"
                        densities="1x"
                        loading="lazy"
                        width="650"
                        height="650"
                        fit="inside"
                        class="portfolio__item-selected"
                        @click="enableFullscreenMode(slotProps.item.index)"
                    />
                </template>
            </template>
            <template #thumbnail="slotProps">
                <MaltImg
                    :src="slotProps.item.portfolioImageMaxImageUrl"
                    :data-testid="`portfolio-item-list-thumbnail-${slotProps.item.index}`"
                    :alt="slotProps.item.title"
                    :title="slotProps.item.title"
                    densities="1x"
                    loading="lazy"
                    height="150"
                    fit="inside"
                    class="portfolio__item thumbnail"
                    :class="{'thumbnail--fullscreen': isFullscreenModeActivated}"
                />
            </template>
        </Galleria>
        <VJoyDividerCta v-else-if="isEditModeEnabled" @click="goToPortfolio">
            {{ $t('profile.section.book.add.label') }}
        </VJoyDividerCta>
        <template #fallback>
            <div v-if="getFirstPortfolioItem" class="portfolio_fallback">
                <MaltImg
                    :src="getFirstPortfolioItem.portfolioImageMaxImageUrl"
                    :title="getFirstPortfolioItem.title"
                    class="portfolio__item image"
                    densities="1x"
                    loading="lazy"
                    alt=""
                    width="650"
                    height="650"
                    fit="inside"
                />
            </div>
        </template>
    </ClientOnly>
</template>
<script setup lang="ts">
    import {MaltImg} from '#components';
    import {computed, ref} from '#imports';
    import {VJoyDividerCta} from '@maltjoy/core-vue';
    import {navigateTo} from '@typed-router';
    import Galleria from 'primevue/galleria';
    import type {PortfolioItem} from '~/types/domain/Portfolio';

    const props = defineProps<{
        portfolioItems: PortfolioItem[];
        isEditModeEnabled: boolean;
    }>();
    const activeIndex = ref(0);
    const maxVisibleThumbnails = ref(3);
    const isFullscreenModeActivated = ref(false);

    const hasItemsToDisplay = computed(() => props.portfolioItems.length > 0);
    const getFirstPortfolioItem = computed(() => (props.portfolioItems.length > 0 ? props.portfolioItems[0] : undefined));

    function enableFullscreenMode(targetIndex: number) {
        // Sentry MALT-2KAC: we have legacy data in database with broken indexes (like only one item with index 6)
        // This algo is trying to find galleria index for this item or pick the 1st one of the list
        const realGalleriaIndex = props.portfolioItems.findIndex((item) => item.index === targetIndex) || 0;

        activeIndex.value = realGalleriaIndex;
        isFullscreenModeActivated.value = true;
    }
    function updateActiveIndex(newActiveIndex: number) {
        activeIndex.value = newActiveIndex;
    }
    function updateIsFullscreenModeActivated(isVisible: boolean) {
        isFullscreenModeActivated.value = isVisible;
        // sorry for that, galleria forces it without removing it
        document.body.classList.remove('p-overflow-hidden');
    }

    function goToPortfolio() {
        return navigateTo(`/portfolio`);
    }
</script>
<style lang="scss">
    @import 'primeicons/primeicons.css';
</style>
<style scoped lang="scss">
    .portfolio {
        &__galleria-wrapper,
        &__fallback {
            height: 375px;
            width: 100%;

            &-nothing {
                height: unset;
            }
        }

        &__item {
            max-height: 100%;
            max-width: 100%;

            &-selected {
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
            }

            &.image {
                object-fit: contain;
                height: auto;
            }

            &.thumbnail {
                height: 100px;
                object-fit: contain;
            }
            &.thumbnail--fullscreen {
                height: 150px;
            }

            &.video-player {
                height: 80%;
                width: 100%;
            }
        }

        &__fallback {
            display: flex;
            justify-content: center;
            align-items: center;

            .image {
                width: calc(100% - 170px);
            }
        }
    }
</style>

<style lang="scss">
    // Common css for embedded and full-screen mode
    .p-galleria {
        .p-galleria-thumbnails,
        .p-galleria-thumbnail-container,
        .p-galleria-thumbnails-content {
            background: none !important;
        }

        .p-galleria-content,
        .p-galleria-item-container,
        .p-galleria-thumbnail-container {
            height: 100%;
            width: 100%;
        }

        &.p-galleria-fullscreen,
        iframe {
            border: none;
        }
    }

    // Embedded mode
    .p-galleria:not(.p-galleria-fullscreen) {
        .p-galleria-content {
            display: block;
        }

        .p-galleria-items-container,
        .p-galleria-thumbnails {
            height: 375px;
            overflow: hidden;
            display: inline-block;
        }

        .p-galleria-items-container {
            width: calc(100% - 170px);
        }

        .p-galleria-thumbnail-container {
            width: 150px;
            padding: 0 !important;
            margin: 0 0 0 var(--joy-core-spacing-4) !important;
        }

        .p-galleria-thumbnail-item {
            height: 120px;
            width: 150px;
            overflow: hidden;
        }
        .p-galleria-thumbnail-item-content {
            height: 100%;
            width: 100%;
        }

        .p-link {
            color: var(--joy-color-secondary-50) !important;
        }
    }

    // Full-screen mode
    .p-galleria.p-galleria-fullscreen {
        height: 100vh;
        width: 80vw;
        display: block;

        .p-galleria-item {
            flex-direction: column;
            color: var(--joy-color-neutral-10);
        }

        .p-galleria-items-container {
            position: absolute;
            top: 0;
            left: 10%;
            margin: 0;
            width: 80%;
            height: calc(100% - 182px);
        }

        .p-galleria-thumbnails {
            position: absolute;
            bottom: 0;
            margin: 0 auto;
            width: 80%;
        }

        .p-galleria-thumbnail-items {
            height: 150px;

            .p-galleria-thumbnail {
                max-height: 150px;
                overflow: hidden;
            }
        }

        .p-galleria-thumbnail-nav-button {
            color: var(--joy-color-neutral-10) !important;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }
        }

        .p-galleria-close-button:hover {
            background: rgba(255, 255, 255, 0.4) !important;
        }
    }

    .p-galleria-mask.p-overlay-mask {
        background-color: rgba(0, 0, 0, 0.9) !important;
    }
</style>
